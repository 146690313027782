.imgs-block {
    position: relative;
    overflow: hidden;

    img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        object-fit: contain;
        width: 100%;
        height: 100%;
        border: none;

        &:nth-child(2), &:nth-child(3) {
            opacity: 0;
        }
    }
}