.gamesList {
    padding-left: 1.6666vw;
    padding-right: 1.6666vw;
    overflow-y: auto;
    max-height: calc(100vh - 173px);
    min-height: calc(100vh - 173px);
    & .games {
        display: flex;
        flex-wrap: wrap;
        gap: calc((100vw - 2 * 1.6666vw - (2 * 47.7vw)) - 4px);

        & .gamesInfo {
            position: relative;
            width: 47.7vw;
            height: 22.447916vw;
            overflow: hidden;
            margin-bottom: 0.833vw;
            border-radius: 2px;
            cursor: pointer;
            
            img {
                width: 100%;
                height: 100%;
                border-radius: 2px;
                object-fit: cover;
            }

            & .gameInfoParent {
                padding: 24px;
                display: flex;
                justify-content: space-between;
                position: absolute;
                bottom: 0;
                width: 100%;
                color: white;
                height: 7.39583vw;
                align-items: flex-end;
                z-index: 1;
                &::before {
                    content: "";
                    background: transparent linear-gradient(180deg, #1D1B2D00 0%, #1D1B2D80 19%, #1D1B2D 100%) 0% 0% no-repeat padding-box;
                    border-radius: 0px 0px 2px 2px;
                    opacity: 0.9;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                }

                & .leftPartGame {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    position: relative;

                    & p {
                        font-family: 'SF Pro Text Medium';
                        font-size: 17px;
                        line-height: 20px;
                        letter-spacing: 0.68px;
                        color: #FFFFFF;
                        text-shadow: 1px 1px 2px #000000;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                        // white-space: nowrap;
                    }

                    & .limits{
                        font-family: 'SF Pro Text Medium';
                        font-size: 14px;
                        line-height: 16px;
                        letter-spacing: 0.56px;
                        color: #FFCC00;
                        text-shadow: 1px 1px 2px #000000;
                        text-transform: capitalize;
                    }

                }

                & .rightParthGame {
                display: flex;
                flex-direction: column;
                gap: 3px;
                position: relative;
                align-items: center;
                & p {
                    font-family: 'SF Pro Text Medium';
                    font-size: 17px;
                    line-height: 20px;
                    letter-spacing: 0.68px;
                    color: #FFFFFF;
                    text-shadow: 1px 1px 2px #000000;
                }
            }
            }

            .game-start-button {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                display: flex;
                background: #2C2E31CC 0% 0% no-repeat padding-box;
                border: 1px solid #FFD35A;
                border-radius: 8px;
                z-index: 2;
                opacity: 0;
                transition: opacity .3s;
                pointer-events: none;
                cursor: default;
                
                button {
                    text-transform: uppercase;
                    margin: auto;
                    background: rgba(44, 46, 49, 0.8) 0% 0% no-repeat padding-box;
                    border: 1px solid rgba(255, 211, 90, 1);
                    border-radius: 8px;
                    padding: 18px 36px;
                    font: normal normal bold 15px/18px SF Pro Text;
                    letter-spacing: 0.6px;
                    color: rgba(255, 211, 90, 1);
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
                    cursor: pointer;
                }
            }
            &:hover {
                .game-start-button {
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }
    }
    &.bigGrid {
        & .games {
            gap: calc((100vw - 2 * 1.6666vw - (4 * 23.6979166vw) - 4px) / 3);

            & .gamesInfo {
                width: 23.6979166vw;
                height: 14.2708vw;

                & .gameInfoParent {
                    padding: 12px;
                    z-index: 1;
                }
            }
        }
    }
}