$bg-color: #1D1B2D;
$text-color: #FFFFFF;
$text-shadow-color: #000000;
$text-hover-color: rgba(255, 211, 90, 1);
$text-shadow-hover-color: #FFCC003D;
$text-border-hover-color: rgb(255, 204, 0);
$text-pressed-color: rgba(255, 204, 0, 1);
$text-selected-color: rgba(242, 242, 242, 1);
$text-shadow-pressed-color: #FFCC003D;
$text-border-pressed-color: rgb(255, 204, 0);
$button-pressed-bg-color: rgba(135, 135, 148, 1);
$navbar-element-border-color: transparent;
$navbar-hover-element-border-color: rgb(255, 204, 0);
$selected-element-border-color: rgba(242, 242, 242, 1);

$base-color: #191628;
$shine-color: #e8e8e8;

@mixin background-gradient {
    background: linear-gradient(0.25turn, transparent, #6a629114, transparent);
}