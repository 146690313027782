.game-iframe {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    iframe {
        transform-origin: left top;
    }
    .close-icon {
        position: fixed;
        top: 10px;
        right: 10px;
        width: 26px;
        height: 26px;
        padding-top: 3px;
        padding-left: 1px;
        svg {
            width: 24px;
            height: 24px;
        }
        .normal-state {
            display: inline-block;
        }
        .hover-state {
            display: none;
        }
        .pressed-state {
            display: none;
        }
        &:hover {
            .normal-state {
                display: none;
            }
            .hover-state {
                display: inline-block;
            }
            .pressed-state {
                display: none;
            }
        }

        &:active {
            .normal-state {
                display: none;
            }
            .hover-state {
                display: none;
            }
            .pressed-state {
                display: inline-block;
            }
            background-color: $button-pressed-bg-color;
        }
    }
}