@media only screen and (max-width: 1280px) {
    header {
        padding-left: 14px;
        padding-right: 14px;
        padding-top: 8px;
        padding-bottom: 4px;

        & .leftPart {
           
        }
        &::after {
        }
        .headerActions {
            
            &-item {
                padding: 10px;

                .normal-state {
                }
                .hover-state {
                }
                &:not(:first-child) {
                    margin-left: 5px;
                }
                &:hover {
                    & .icon {
                        width: 30px;
                        height: 30px;
                    }
                }
                & .icon {
                    & svg {
                        height: 30px;
                        width: 30px;
                    }
                }
                &  .name {
                    font-size: 10px;
                    line-height: 12px;
                    margin-top: 2px;
                    letter-spacing: 0.58px;
                }
                &:hover, &:active {
                    .normal-state {
                    }
                    .hover-state {
                    }
                    &  .name {
                    }
                }
                &:active {
                }
            }
        }
        .user-info {
            font-size: 13px;
            line-height: 29px;
            & > div {
            }
            &-lable{
                font-size: 13px;
                line-height: 15px;
            }
            svg {
                width: 28px;
            }
           
            & .balance{
                font-size: 13px;
                line-height: 15px;
            }
        }
    }
    
    nav {
        margin-bottom: 21px;
        padding-top: 14px;
        align-items: flex-end;
        height: 89px;
        gap: 11px;
        .nav-swiper {
             width: 77.34375vw;

            .nav-swiper-bg {
                background: transparent linear-gradient(180deg, #2C2E3100 0%, #2C2E31 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 2px 4px #00000099;
                border: 1px solid #F2F2F21A;
                border-radius: 8px;
                width: 77.34375vw;
                height: 72px;
            }
        }
        & .navLeft {
            margin-bottom: 11px;
            gap: 11px;
            padding-left: 28px;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            padding-bottom: 10px;

            & span {
                font-size: 12px;
                line-height: 14px;
            }
        }
        & .nav-swiper {

            & .menuUl {
                justify-content: flex-start;
                flex-wrap: wrap;
                align-items: flex-end;
                height: 85px;
                width: 73.59375vw;

                & .swiper-slide {
                    margin-right: 25px;

                    & > div {
                        & .item-content {
                            padding-bottom: 7px;

                            &-title{
                                font-size: 13px;
                                letter-spacing: 1.5px;
                            }
                        }
                        & span {
                            font-size: 10px;
                            line-height: 12px;
                            letter-spacing: 0.4px;
                            color: #1D1B2D;
                            padding: 0px 3px;
                        }
                        &::before{
                            bottom: 4px;
                        }
                    }
        
                    &.active {
                        &:before {
                        }
                    }
        

        
                    &:hover {
                        &::before {
                        }
                        & .item-content {
                            &-title {
                            }
                        }
        
                    }
                    &:active {
                    }
        
                    
                }
            }
        }
    
        & .menu {
        }
    
        & .nav-right {
            margin-bottom: 9px;
            padding-right: 24px;
            & .line {
                margin-top: -2px;
                height: 17px;
                margin-bottom: 4px;
            }
    
            & svg {
                width: 23px;
                height: 23px;
                margin-left: 0px; 
                margin-top: 0px;
            }
    
            .item {
                width: 23px;
                height: 23px;
                &.selected {
                    &:after{
                        left: 4px;
                        bottom: -5px;
                        width: 16px;
                    }
                    & svg {
                        &.hover-state {
                            margin-top: -1px;
                        }
                    }
                }

                &:hover {
                    & svg {
                        &.hover-state {
                            margin-top: -1px;
                        }
                    }
                }
            }
        }
        .nav-right, .navLeft {
            width: calc((100vw - 77.34375vw - 22px) / 2);
        }
    }

    .gamesList {
        padding-left: 1.5625vw;
        padding-right: 1.5625vw;
        max-height: calc(100vh - 157px);
        min-height: calc(100vh - 157px);
        & .games {
            gap: calc((100vw - 3.125vw - (2 * 48.125vw)));
            & .gamesInfo {
                width: 48.125vw;
                height: 23.75vw;
                margin-bottom: 0.625vw;
                img {
                }
    
                & .gameInfoParent {
                    padding-right: 7px;
                    &::before {
                    }
    
                    & .leftPartGame {
    
                        & p {
                            font-size: 15px;
                            line-height: 18px;
                        }
    
                        & .limits {
                            font-size: 12px;
                            line-height: 14px;
                        }
    
                    }
    
                    & .rightParthGame {
                        & p {
                            font-size: 15px;
                            line-height: 18px;
                        }
                        svg {
                            height: 25px;
                        }
                    }
            }
    
            }
        }
        &.bigGrid {
            gap: calc((100vw - 3.125vw - (4 * 23.75vw) / 3));
            & .gamesInfo {
                width: 23.75vw;
                height: 15.625vw;
                & .gameInfoParent {
                    
                }
            }
        }
    }

    .languages-popup {
        top: 156px;
        .languages {
            &-list {
                .languages-line {
                    .languages-icon {
                        svg {
                        }
                    }
                    span {
                    }
                    
                    .checked-icon {
                    }
                }
                
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    header {
        & .leftPart {
           
        }
        &::after {
        }
        .headerActions {
            
            &-item {
                .normal-state {
                }
                .hover-state {
                }
                &:not(:first-child) {
                    margin-left: 0px;
                }
                & .icon {
                    &  svg {
                    }
                }
                &  .name {
                }
                &:hover, &:active {
                    .normal-state {
                    }
                    .hover-state {
                    }
                    &  .name {
                    }
                }
                &:active {
                }
            }
            .menu-button{
                .name {
                    margin-top: 0px;
                }
                .icon {
                    svg {
                        width: 30px;
                        height: 28px;
                    }
                }
            }
        }
        .user-info {
            & > div {
            }
            &-lable{
            }
            svg {
            }
           
            & .balance{
            }
        }
    }
    
    nav {
        margin-bottom: 21px;
        padding-top: 14px;
        align-items: flex-end;
        height: 89px;
        gap: 11px;
        .nav-swiper {
             width: 70.8333vw;

            .nav-swiper-bg {
                background: transparent linear-gradient(180deg, #2C2E3100 0%, #2C2E31 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 2px 4px #00000099;
                border: 1px solid #F2F2F21A;
                border-radius: 8px;
                width: 70.8333vw;
                height: 72px;
            }
        }
        & .navLeft {
            margin-bottom: 11px;
            gap: 11px;
            padding-left: 20px;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            padding-bottom: 10px;

            & span {
                font-size: 12px;
                line-height: 14px;
            }
        }
        & .nav-swiper {

            & .menuUl {
                justify-content: flex-start;
                flex-wrap: wrap;
                align-items: flex-end;
                height: 85px;
                width: 65.625vw;

                & .swiper-slide {
                    margin-right: 25px;

                    & > div {
                        & .item-content {
                            padding-bottom: 7px;

                            &-title{
                                font-size: 13px;
                                letter-spacing: 1.5px;
                            }
                        }
                        & span {
                            font-size: 10px;
                            line-height: 12px;
                            letter-spacing: 0.4px;
                            color: #1D1B2D;
                            padding: 0px 3px;
                        }
                        &::before{
                            bottom: 4px;
                        }
                    }
        
                    &.active {
                        &:before {
                        }
                    }
        

        
                    &:hover {
                        &::before {
                        }
                        & .item-content {
                            &-title {
                            }
                        }
        
                    }
                    &:active {
                    }
        
                    
                }
            }
        }
    
        & .menu {
        }
    
        & .nav-right {
            margin-bottom: 9px;
            padding-right: 16px;

            & .line {
                margin-top: -2px;
                height: 17px;
                margin-bottom: 4px;
            }
    
            & svg {
                width: 23px;
                height: 23px;
                margin-left: 0px; 
                margin-top: 0px;
            }
    
            .item {
                width: 23px;
                height: 23px;
                &.selected {
                    &:after{
                        left: 4px;
                        bottom: -5px;
                        width: 16px;
                    }
                    & svg {
                        &.hover-state {
                            margin-top: -1px;
                        }
                    }
                }

                &:hover {
                    & svg {
                        &.hover-state {
                            margin-top: -1px;
                        }
                    }
                }
            }
        }
        .nav-right, .navLeft {
            width: calc((100vw - 70.8333vw - 22px) / 2);
        }
    }

    .gamesList {
        padding-left: 2.6vw;
        padding-right: 2.6vw;
        max-height: calc(100vh - 164px);
        min-height: calc(100vh - 164px);
        height: calc(100vh - 164px);
        & .games {
            gap: calc((100vw - 5.2vw - (2 * 46.8vw)));
            & .gamesInfo {
                width: 46.8vw;
                height: 50.13vw;
                margin-bottom: 1.0416vw;
                img {
                }
    
                & .gameInfoParent {
                    height: 24.739583vw;
                    padding-right: 17px;
                    &::before {
                    }
    
                    & .leftPartGame {
    
                        & p {
                            font-size: 15pt;
                            line-height: 18pt;
                            letter-spacing: 0.6pt;
                        }
    
                        & .limits {
                            font-size: 12pt;
                            line-height: 14pt;
                            letter-spacing: 0.48pt;
                        }
    
                    }
    
                    & .rightParthGame {
                        & p {
                            font-size: 15pt;
                            line-height: 18pt;
                            letter-spacing: 0.6pt;
                        }
                        svg {
                        }
                    }
            }
    
            }
        }
        &.bigGrid {
            gap: calc((100vw - 5.2vw - (4 * 22.916vw)) / 3);
            .games {
                & .gamesInfo {
                    width: 22.8vw;
                    height: 34.895833vw;
    
                    & .gameInfoParent {
                        display: flex;
                        height: 19.53125vw;
                        align-items: flex-start;
                        flex-direction: column-reverse;
                        justify-content: flex-start;
                        gap: 1.5625vw;
                        padding: 7px;

                        & .leftPartGame {
                            gap: 8px;
                            & p {
                                font-size: 12px;
                                line-height: 14px;
                                letter-spacing: 0.48px;
                            }
    
                            & .limits {
                                font-size: 8pt;
                                line-height: 12px;
                                letter-spacing: 0.4px;
                            }
                        }
    
                        & .rightParthGame {
                            gap: 0px;
                            margin-left: -7px;
                            & p {
                                font-size: 12px;
                                line-height: 14px;
                            }
                            svg {
                                // height: 25px;
                            }
                        }
    
                       
                        
                    }
                }
            }
            
        }
    }
    li.show-when-mobile {
        display: flex;
    }
    
    li.hide-when-mobile {
        display: none;
    }
    .popup {
        right: 3%;
        top: 218px;
    }
    .languages-popup {
        .languages {
            &-list {
                padding-top: 20px;
                .languages-line {
                    height: 44px;
                    min-height: 44px;
                    .languages-icon {
                        svg {
                            width: 28px;
                            height: 28px;
                        }
                    }
                    span {
                    }
                    
                    .checked-icon {
                    }
                }
                
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .game-start-button {
        display: none !important;
    }
    header {
        padding-right: 6px;
        padding-top: 6px;
        padding-bottom: 5px;

        & .leftPart {
           
        }
        &::after {

        }
        .headerActions {
            
            &-item {
                .normal-state {
                }
                .hover-state {
                }
                &:not(:first-child) {
                    margin-left: 6px;
                }
                & .icon {
                    &  svg {
                    }
                }
                &  .name {
                    margin-top: 0px;
                }
                &:hover, &:active {
                    .normal-state {
                    }
                    .hover-state {
                    }
                    &  .name {
                    }
                }
                &:active {
                }
                &:hover {
                    .icon {
                        width: 23px;
                        height: 23px;
                    }
                }
            }
        }
        .user-info {
            & > div {
            }
            &-lable{
            }
            svg {
            }
           
            & .balance{
            }
        }
    }
    
    nav {
        margin-bottom: 21px;
        padding-top: 4px;
        align-items: flex-end;
        height: auto;
        gap: 17px;
        flex-wrap: wrap;
        padding-left: 16px;

        .nav-swiper {
            width: calc(100vw - 16px * 2);
            order: 0;

            .nav-swiper-bg {
                background: transparent linear-gradient(180deg, #2C2E3100 0%, #2C2E31 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 2px 4px #00000099;
                border: 1px solid #F2F2F21A;
                border-radius: 8px;
                width: calc(100vw - 16px * 2);
                height: 72px;
            }
        }
        & .navLeft {
            width: auto;
            border: none;
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
            order: 1;
            flex-direction: row;
            height: auto;
            margin-bottom: 2px;

            & span {
                font-size: 10px;
                line-height: 14px;
            }
        }
        & .nav-swiper {

            & .menuUl {
                justify-content: flex-start;
                flex-wrap: wrap;
                align-items: flex-end;
                height: 71px;
                margin-left: 16px;
                max-width: none;
                width: calc(100vw - 32px - 32px);

                & .swiper-slide {
                    margin-right: 25px;

                    & > div {
                        & .item-content {
                            padding-bottom: 6px;

                            &-title{
                                font-size: 10px;
                                letter-spacing: 1.5px;
                            }
                        }
                        & span {
                            font-size: 8px;
                            line-height: 12px;
                            letter-spacing: 0.4px;
                            color: #1D1B2D;
                            padding: 0px 3px;
                        }
                        &::before{
                            bottom: 4px;
                        }
                    }
        
                    &.active {
                        &:before {
                        }
                    }
        

        
                    &:hover {
                        &::before {
                        }
                        & .item-content {
                            &-title {
                            }
                        }
        
                    }
                    &:active {
                    }
        
                    
                }
            }
        }
    
        & .menu {
        }
    
        & .nav-right {
            margin-left: 5%;
            margin-bottom: -3px;
            order: 2;
            flex-direction: row;
            height: auto;
            padding-right: 13px;

            & .line {
                margin-top: -2px;
                height: 17px;
                margin-bottom: 4px;
            }
    
            & svg {
                width: 23px;
                height: 23px;
                margin-left: 0px; 
                margin-top: 0px;
            }
    
            .item {
                width: 23px;
                height: 23px;
                &.selected {
                    &:after{
                        left: 4px;
                        bottom: -5px;
                        width: 16px;
                    }
                    & svg {
                        &.hover-state {
                            margin-top: -1px;
                        }
                    }
                }

                &:hover {
                    & svg {
                        &.hover-state {
                            margin-top: -1px;
                        }
                    }
                }
            }
        }
        .nav-right, .navLeft {
            width: auto;
            border: none;
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .gamesList {
        & .games {
    
            & .gamesInfo {
                img {
                }
    
                & .gameInfoParent {
                    padding: 12px;
                    &::before {
                    }
    
                    & .leftPartGame {
                        max-width: 80%;
                        & p {
                        }
    
                        & .limits {
                        }
    
                    }
    
                    & .rightParthGame {
                        & p {
                        }
                        svg {
                        }
                    }
            }
    
            }
        }
        &.bigGrid {
            .games {
                & .gamesInfo {
                    & .gameInfoParent {
                        & .leftPartGame {
                            gap: 2px;
                            & p {
                                font-size: 10px;
                            }
    
                            & .limits {
                                font-size: 8px;
                            }
                        }
    
                        & .rightParthGame {
                            & p {
                            }
                            svg {
                            }
                        }
                    }
                }
            }
        }
    }
    .popup {
        right: 1%;
        top: 72px;
        height: calc(100vh - 110px);
        .popup-header {
            min-height: 48px;
            height: 48px;
            span {
                font-size: 14px;
                line-height: 16px;
            }
            .close-icon {
                top: 11px;
                right: 10px;
            }
        }
        &.menu-popup{
            height: fit-content;
        }
    }
    .languages-popup {
        .languages {
            
            &-list {
                margin-top: 14px;
                overflow-y: auto;
                .languages-line {
                    height: 41px;
                    min-height: 41px;
                    padding-left: 18px;
                    .languages-icon {
                        svg {
                            width: 25px;
                            height: 25px;
                        }
                    }
                    span {
                        margin-left: 11px;
                        font-size: 11px;
                    }
                    
                    .checked-icon {
                        margin-left: auto;
                        margin-right: 11px;
                        width: 20px;
                        height: 26px; 
                    }
                }
                
            }
        }
    }
}

@media only screen and (max-width: 540px) {
    header {
        padding-top: 5px;
        padding-bottom: 5px;
        & .leftPart {
           
        }
        &::after {
        }
        .headerActions {
            
            &-item {
                padding: 6px;
                .normal-state {
                }
                .hover-state {
                }
                &:not(:first-child) {
                }
                & .icon {
                    &  svg {
                        height: 23px;
                        width: 23px;
                    }
                }
                &  .name {
                    font-size: 8px;
                    line-height: 10px;
                }
                &:hover, &:active {
                    .normal-state {
                    }
                    .hover-state {
                    }
                    &  .name {
                    }
                }
                &:active {
                }
            }
        }
        .user-info {
            font-size: 10px;
            font-size: min(2vw, 10px);
            line-height: 24px;
            & > div {
            }
            &-lable {
                font-size: 10px;
                line-height: 12px;
            }
            svg {
                width: 22px;
            }
           
            & .balance {
                font-size: 11px;
                line-height: 11px;
                margin-left: 5px;
            }
        }
    }
    


    .gamesList {
        & .games {
            gap: calc((100vw - 5.2vw - (2 * 45.83vw)));
            & .gamesInfo {
                height: 49.583vw;
                width: 45.83vw;
                margin-bottom: 1.66vw;
                img {
                }
    
                & .gameInfoParent {
                    height: 25.833vw;
                    &::before {
                    }
    
                    & .leftPartGame {
    
                        & p {
                            font-size: 10px;
                            line-height: 17px;
                            letter-spacing: 0.4px;
                        }
    
                        & .limits {
                            font-size: 8px;
                            line-height: 10px;
                            letter-spacing: 0.32px;
                        }
    
                    }
    
                    & .rightParthGame {
                        & p {
                            font-size: 10px;
                            line-height: 12px;
                            letter-spacing: 0.4px;
                        }
                        svg {
                            height: 22px;
                        }
                    }
            }
    
            }
        }
        &.bigGrid {
            gap: calc((100vw - 5.2vw - (4 * 22.083vw)) / 3);
            & .gamesInfo {
                height: 32.083vw;
                width: 22.083vw;
                & .gameInfoParent {
                    height: 19.583vw;
                    & .leftPartGame {
                        & p {
                        }

                        & .limits {
                        }
                    }

                    & .rightParthGame {
                        & p {
                        }
                        svg {
                            height: 20px;
                        }
                    }
                }
            }
        }
    }

    .popup {
        right: 1%;
        top: 62px;
    }
}