$animation-duration: 1s;
$avatar-offset: 52 + 16;

@keyframes shine-lines {
    0% {
        background-position: -100px;
    }
    40% {
        background-position: 140px;
    }
    100% {
        background-position: 140px;
    }
   
}


@keyframes shine-avatar {
    0% {
        background-position: -100px + $avatar-offset;
    }
    40% {
        background-position: 140px + $avatar-offset;
    }
    100% {
        background-position: 140px + $avatar-offset;
    }
    
}
   

@keyframes loading {  
    to {
        background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
}