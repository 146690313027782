.languages-popup{
    right:1.68%;
    top: 173px;
    position: absolute;
    z-index: 1;
    width: 260px;
    max-height: calc(100vh - 226px);
    height: fit-content;
  
    & .languages-list{
        display: flex;
        gap: 8px;
        flex-direction: column;
        padding-top: 23px;
        height: calc(100% - 60px);
        overflow-y: auto;
        padding-bottom: 7px;
 
        & .languages-line{
            padding-left: 23px;
            display: flex;
            align-items: center;
            width: 100%;
            height: 48px;
            min-height: 48px;
            cursor: pointer;
            padding-top: 8px;
            padding-bottom: 5px;
            
            & .checked-icon{
                padding-top: 3px;
                width: 28px;
                height: 28px;
                visibility: hidden;
                margin-left: auto;
                margin-right: 11px;
            }
            & span {
                margin-left: 13px;
                font-size: 14px;
                line-height: 20px;
                font-family: 'SF Pro Text Medium';
                letter-spacing: 0.56px;
                color: #FFFFFF;
                text-shadow: 1px 1px 2px #000000;
                
            }
            &.is-selected {
                & .checked-icon{
                    visibility: visible;
                }
            }
            &:hover{
                & span {
                    color: $text-border-hover-color;
                }
            }
            &:active {
                background: #FFD35A33 0% 0% no-repeat padding-box;
                & span {
                    color: #FFCC00;
                }
            }
        }
        & .languages-icon {
            svg {
                width: 31px;
                height: 31px;
            }
        }
    }
}