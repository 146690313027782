.period_history{
    position: absolute;
    right: 1.0%;
    top: 293px;
    width: 138px;
    height: 200px;
    // background: #1D1B2DE6 0% 0% no-repeat padding-box;
    border: 1px solid #87879480;
    border-radius: 4px;
    z-index: 2;

    & :hover{
        color: #FFCC00;
        
        & span:hover{
            color: #FFCC00;
        }
      
    }
    
    & .period_list:active {
        background: #FFD35A33 0% 0% no-repeat padding-box;
        color: #FFCC00;
        & span {
            color: #FFCC00;
        }
    }
   
    & .period_list{
        cursor: pointer;
        padding-left: 10px;
        padding-right: 7px;
        width: 136px;
        height: 40px;
        display: flex;
        justify-content: space-between;
        z-index: 1;
        position: relative;
        align-items: center;
       

        & span{
            font: normal normal 300 14px/20px SF Pro Text;
            letter-spacing: 0.56px;
            color: #FFFFFF;
            text-shadow: 1px 1px 2px #000000;
            font-size: 13px;
            
        }
        & .checked_parent{
            width: 22px;
            height: 22px;
        }
    }

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        backdrop-filter: blur(50px);
        -webkit-backdrop-filter: blur(50px);
        z-index: 0;
    }
}