@font-face {
    font-family: 'SF Compact Text Heavy';
    src: url(@assets/fonts/sf_compact_text/SF-Compact_Text_Heavy.eot);
    src: local('SF Compact Text Heavy'), local('SF-Compact_Text_Heavy'),
        url(@assets/fonts/sf_compact_text/SF-Compact_Text_Heavy.eot?#iefix) format('embedded-opentype'),
        url(@assets/fonts/sf_compact_text/SF-Compact_Text_Heavy.woff2) format('woff2'),
        url(@assets/fonts/sf_compact_text/SF-Compact_Text_Heavy.woff) format('woff'),
        url(@assets/fonts/sf_compact_text/SF-Compact_Text_Heavy.ttf) format('truetype');
}

@font-face {
    font-family: 'SF Pro Text Bold';
    src: url(@assets/fonts/sf_pro_text/SFProText-Bold.eot);
    src: local('SF Pro Text Bold'), local('SFProText-Bold'),
    url(@assets/fonts/sf_pro_text/SFProText-Bold.eot?#iefix) format('embedded-opentype'),
    url(@assets/fonts/sf_pro_text/SFProText-Bold.woff2) format('woff2'),
    url(@assets/fonts/sf_pro_text/SFProText-Bold.woff) format('woff'),
    url(@assets/fonts/sf_pro_text/SFProText-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'SF Pro Text Heavy';
    src: url(@assets/fonts/sf_pro_text/SFProText-Heavy.eot);
    src: local('SF Pro Text Heavy'), local('SFProText-Heavy'),
    url(@assets/fonts/sf_pro_text/SFProText-Heavy.eot?#iefix) format('embedded-opentype'),
    url(@assets/fonts/sf_pro_text/SFProText-Heavy.woff2) format('woff2'),
    url(@assets/fonts/sf_pro_text/SFProText-Heavy.woff) format('woff'),
    url(@assets/fonts/sf_pro_text/SFProText-Heavy.ttf) format('truetype');
}

@font-face {
    font-family: 'SF Pro Text Light';
    src: url(@assets/fonts/sf_pro_text/SFProText-Light.eot);
    src: local('SF Pro Text Light'), local('SFProText-Light'),
    url(@assets/fonts/sf_pro_text/SFProText-Light.eot?#iefix) format('embedded-opentype'),
    url(@assets/fonts/sf_pro_text/SFProText-Light.woff2) format('woff2'),
    url(@assets/fonts/sf_pro_text/SFProText-Light.woff) format('woff'),
    url(@assets/fonts/sf_pro_text/SFProText-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'SF Pro Text Medium';
    src: url(@assets/fonts/sf_pro_text/SFProText-Medium.eot);
    src: local('SF Pro Text Medium'), local('SFProText-Medium'),
    url(@assets/fonts/sf_pro_text/SFProText-Medium.eot?#iefix) format('embedded-opentype'),
    url(@assets/fonts/sf_pro_text/SFProText-Medium.woff2) format('woff2'),
    url(@assets/fonts/sf_pro_text/SFProText-Medium.woff) format('woff'),
    url(@assets/fonts/sf_pro_text/SFProText-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'SF Pro Text';
    src: url(@assets/fonts/sf_pro_text/SFProText-Medium.eot);
    src: local('SF Pro Text'), local('SFProText'),
    url(@assets/fonts/sf_pro_text/SFProText.eot?#iefix) format('embedded-opentype'),
    url(@assets/fonts/sf_pro_text/SFProText.woff2) format('woff2'),
    url(@assets/fonts/sf_pro_text/SFProText.woff) format('woff'),
    url(@assets/fonts/sf_pro_text/SFProText.ttf) format('truetype');
}




