header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 6px;
    position: relative;
    & .leftPart{
        display: flex;
        justify-content: center;
       
    }
    &::after {
        content: "";
        box-shadow: 0px 2px 4px #000000;
        border-bottom: 1px solid #878794;
        opacity: 0.5;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .headerActions{
        width: 100%;
        list-style: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        
        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 12px;
            padding-top: 14px;
            cursor: pointer;
            .normal-state {
                display: inline-block;
            }
            .hover-state {
                display: none;
            }
            .pressed-state {
                display: none;
            }
            &:not(:first-child){
                margin-left: 8px;
            }
            & .icon {
                display: flex;
                justify-content: center;
                &  svg{
                    height: 36px;
                    width: 36px;
                }
            }
            &  .name {
                font-size: 13.2px;
                font-family: 'SF Pro Text Light';
                line-height: 16px;
                font-weight: 300;
                letter-spacing: 1.2px;
                color: #FFFFFF;
                text-shadow: 1px 1px 2px #000000;
                text-transform: capitalize;
            }
            &:hover {
                .normal-state {
                    display: none;
                }
                .pressed-state {
                    display: none;
                }
                .hover-state {
                    display: inline-block;
                }
                &  .name {
                    color: $text-hover-color;
                    text-shadow: unset;
                }
            }

            &:active {
                .normal-state {
                    display: none;
                }
                .pressed-state {
                    display: inline-block
                }
                .hover-state {
                    display: none;
                    // path {
                    //     fill: $text-pressed-color;
                    // }
                }
                &  .name {
                    color: $text-pressed-color;
                    text-shadow: unset;
                }
            }
        }
    }
    .user-info {
        font-size: 15px;
        line-height: 30px;
        font-family: 'SF Pro Text Bold';
        letter-spacing: 0.6px;
        color: #FFFFFF;
        text-shadow: 1px 1px 2px #000000;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        padding-top: 2px;
        & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            padding-right: 12px;
        }
        &-lable{
            font: normal normal bold 15px/18px SF Pro Text;
            letter-spacing: 0.6px;
            color: #FFFFFF;
            text-shadow: 1px 1px 2px #000000;
            text-transform: capitalize;
        }
        svg {
            width: 33px;
        }
       
        & .balance{ 
            font: normal normal 900 15px/18px SF Pro Text;
            letter-spacing: 0.6px;
            color: #FFCC00;
            text-shadow: 1px 1px 2px #000000;
            margin-left: 8px;
        }
    }
}
    
    