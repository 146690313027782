.app {
    position: relative;
    background: $bg-color 0% 0% no-repeat padding-box;
    &-bg {
        position: fixed;
        left: 0;
        top:0;
        width: 100vw;
        height: 100vh;
        background: #2b2b2b url(@assets/images/bg.svg) 0% 0% no-repeat padding-box;
        mix-blend-mode: soft-light;
        z-index: 1;
        background-size: cover;
        background-position: center;
    }
    &-body {
        position: relative;
        z-index: 2;
    }
}

@media only screen and (max-width: 1280px) {
    .app {
        &-bg {
            background-image: url(@assets/images/bg1280.svg);
        }
    }
}

@media only screen and (max-width: 768px) {
    .app {
        &-bg {
            background-image: url(@assets/images/bg768.svg);
        }
    }
}