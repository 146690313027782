.img-loader {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    &-loading {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        @include background-gradient;
        background-repeat: no-repeat;
        background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px; 
        background-position: -315px 0, 0 0, 0px 190px, 50px 195px; 
        animation: loading 1.5s infinite;
    }
    img {
        width: 100%;
    }
}