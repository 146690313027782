nav {
    padding-top: 12px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    gap: 12px;
    height: 106px;

    & .navLeft {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 35px;
        height: calc(100% - 20px);
        padding-left: 32px;
        align-items: flex-end;
        padding-bottom: 16px;

        & span {
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            font-family: 'SF Pro Text Light';
            letter-spacing: 0.56px;
            color: #F2F2F2;
            text-shadow: 1px 1px 2px #000000;
            white-space: nowrap;
        }
    }
    & .nav-swiper {
        position: relative;
        width: 56.4583vw;

        .nav-swiper-bg {
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 56.4583vw;
            height: 82px;
            background: transparent linear-gradient(180deg, #2C2E3100 0%, #2C2E31 100%) 0% 0% no-repeat padding-box;
            box-shadow: 0px 2px 4px #00000099;
            border: 1px solid #F2F2F21A;
            border-radius: 8px;
        }
        
        & .menuUl {
            display: flex;
            flex-wrap: wrap;
            max-width: 72.3vw;
            align-items: flex-end;
            margin-left: 28px;
            width: 53.541666vw;
            height: 96px;

            & .swiper-slide {
                width: fit-content;

                & > div {
                    cursor: pointer;
                    height: 100%;

                    & .item-content {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        height: 100%;
                        padding-bottom: 15px;
                        
                        &-title {
                            font-family:  'SF Pro Text Light';
                            text-transform: uppercase;
                            font-weight: 600;
                            font-size: 15px;
                            line-height: 18px;
                            letter-spacing: 0.6px;
                            color: #F2F2F2;
                            text-shadow: 1px 1px 2px #000000;
                            white-space: nowrap;
                        }
                        .imgs-block {
                            width: 100%;
                            height: 100%;
                            max-height: 55%;
                            margin-top: auto;
                        }
                    }

                    

                    &:before {
                        background: transparent 0% 0% no-repeat padding-box;
                        content: "";
                        width: 100%;
                        height: 2px;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: 9px;
                        transition: background-color .3s cubic-bezier(.645,.045,.355,1);
                    }
                    &.active {
                        & .item-content {
                            &-title {
                                text-shadow: unset;
                                color: $text-selected-color;
                            }
                        }
                        &:before {
                            box-shadow: 0px 2px 4px rgba(0, 0, 0, 1);
                            background-color: $selected-element-border-color;
                        }
                    }

                    &:hover {
                        &:before {
                            // background-color: $navbar-hover-element-border-color;
                            // box-shadow: 0px 2px 4px rgba(0, 0, 0, 1);
                        }
                        
                        & .item-content {
                            &-title {
                                text-shadow: unset;
                                color: $text-hover-color;
                            }
                            img {
                                &:nth-child(1) {
                                    opacity: 0;
                                }
                                &:nth-child(2) {
                                    opacity: 1;
                                }
                            }
                        }

                    }
                    &:active {
                        border-radius: 2px;
                        & .item-content {
                            &-title {
                                color: $text-pressed-color;
                            }
                            img {
                                &:nth-child(1) {
                                    opacity: 0;
                                }
                                &:nth-child(2) {
                                    opacity: 0;
                                }
                                &:nth-child(3) {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                    & .is-new {
                        width: 100%;
                    }
                    & span {
                        box-shadow: 0px 2px 4px #000000;
                        border-radius: 2px;
                        background: #FFCC00 0% 0% no-repeat padding-box;
                        height: 14px;
                        padding: 0px 4px;
                        font-family: 'SF Pro Text Bold';
                        font-size: 12px;
                        line-height: 14px;
                        letter-spacing: 0.48px;
                        color: #1D1B2D;
                        transform: translateX(-9px);
                    }
                }
            }
        }
    }
        

    & .menu {
        display: flex;
        justify-content: center;
    }

    & .nav-right {
        display: flex;
        justify-content: flex-end;
        gap: 7px;
        align-items: flex-end;
        padding-right: 26px;
        padding-bottom: 4px;

        & .line {
            margin-bottom: 8px;
            height: 24px;
            box-shadow: 0px 2px 4px #000000;
            border: 1px solid #878794;
            opacity: 0.5;
        }

        & svg {
            width: 36px;
            height: 36px;
            margin-left: 2px;
            margin-top: 3px;
        }

        .item {
            width: 40px;
            height: 40px;
            cursor: pointer;
            position: relative;
            &.selected {
                .hover-state {
                    display: inline-block;
                }
                .normal-state {
                    display: none;
                }
            }
            .normal-state {
                display: inline-block;
            }
            .hover-state {
                display: none;
            }
            .pressed-state {
                display: none;
            }
            &:hover {
                .normal-state {
                    display: none;
                }
                .pressed-state {
                    display: none;
                }
                .hover-state {
                    display: inline-block;
                }
            }
            &:active {
                .normal-state {
                    display: none;
                }
                .hover-state {
                    display: none;
                }
                .pressed-state {
                    display: inline-block;
                }
            }
        }
    }
    & .nav-right, & .navLeft {
        border-bottom: 1px solid rgba(242, 242, 242, 0.1);
        width: calc((100vw - 56.4583vw - 2 * 12px) / 2);
        height: 83px;
    }
}

