.menu-popup {
    width: 204px;
    height: 192px;
}
.menu-button{
    .name {
        margin-top: 0px;
    }
    .icon {
        svg {
            width: 39px;
            height: 35px;
        }
    }
}