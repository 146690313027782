.popup {
    & .second-history{
        justify-content: right;
        font-family: SF Pro Text;
        margin-left: 28px;
        width: 250px;
        height: 55px;
        font-size: 14px;
        padding-top: 32px;
        text-align: right;
        border-bottom: 1.2px solid rgba(135,135,148, 0.5);
        letter-spacing: 0.56px;
        color: rgba(255, 255, 255, 1);
        display: flex;
        & span{
            width: 96px;
            height: 16px;
            white-space: nowrap;
            cursor: pointer;
        }
        & div{
            padding-top: 1px;
            width: 22px;
            height: 22px;
        }
    }

    width: 300px;
    height: 258px;
    display: flex;
    flex-direction: column;
}

.third-history{
   margin-left: 24px;
   margin-right: 25px;
   font-size: 14px;
   display: flex;
   flex-direction: column;
   gap:20px;
   margin-top: 24px;
   & div{
       display: flex;
       justify-content: space-between;
       
       span {
           &:nth-child(1) {
               text-transform: lowercase;
               &::first-letter {
                   text-transform: uppercase;
               }
           }
       }
   }
}