.bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: -1;
    &-line {
        box-shadow: 0px 2px 4px #000000;
        border-top: 1.2px solid #878794;
        opacity: 0.14;
        margin-top: 4px;
    }
}