.popup {
    position:absolute;
    right:1.68%;
    top: 174px;
    letter-spacing: 0.56px;
    font-family: 'SF Pro Text';
    z-index: 1;
    color: #FFFFFF;
    text-shadow: 1px 1px 2px #000000;
    background: #252629E6 0% 0% no-repeat padding-box;
    border: 1px solid #F2F2F21A;
    border-radius: 8px;
    & .popup-header {
        position: relative;
        width: 100%;
        height: 60px;
        min-height: 60px;
        padding-left: 18px;
        border-bottom: 1.2px solid rgba(135,135,148, 0.5);
   
        & .close-icon {
            position: absolute;
            top: 18px;
            right: 15px;
            cursor: pointer;
            
            svg {
                width: 28px;
                height: 28px;
            }
            & .normal-state {
                display: inline-block;
            }
            & .hover-state {
                display: none;
            }
            & .pressed-state {
                display: none;
            }
            &:hover {
                & .normal-state {
                    display: none;
                }
                & .pressed-state {
                    display: none;
                }
                & .hover-state {
                    display: inline-block;
                } 
            }
            &:active{
                & .normal-state {
                    display: none;
                }
                & .pressed-state {
                    display: inline-block;
                }
                & .hover-state {
                    display: none;
                }
            }
        }
        & span{
            position: absolute;
            text-shadow: 1px 1px 2px #000000;
            text-transform: uppercase;
            font-size: 15px;
            letter-spacing: 0.9px;
            left: 50%;
            transform: translate(-50%, -50%);
            top: 50%;
        }
    }
}

.popup-wraper{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 2;
}