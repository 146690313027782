.alertMessage {
  display: none;
  top: calc(144px * 1);
  left: 0;
  text-align: left;
  font: calc(15px * 1)/calc(28px * 1) "SF Pro Text Medium";
  letter-spacing: 0;
  color: #FFFFFF;
  z-index: 3;
  position: fixed;
  right: 0;
  margin: auto;
  width: fit-content;
  min-width: calc(506px * 1);
  max-width: calc(506px * 1);
  padding: calc(14px * 1) calc(16px * 1);
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: calc(16px * 1);
  &.show {
    display: flex;
  }
  & .msg {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #1D1B2DB3 0% 0% no-repeat padding-box;
    border-radius: calc(29px * 1) calc(8px * 1) calc(8px * 1) calc(29px * 1) ;
    width: calc(58px * 1);
    transition: width,border 0.7s ease-in-out;
    &.in {
      animation: animateWidth 0.7s;
      width: 100%;
      & .text{
        animation: fadeIn 1s ease-in ;
      }
    }
    &.out {
      animation-iteration-count: 1;
      animation-timing-function: ease-in-out;
      animation: animateWidthOut 0.3s;
      width: calc(58px * 1);
      border-radius: calc(29px * 1);
      & .text {
        display: none;
        opacity: 0;

      }
    }
    & .text {
      max-width: calc(100% - 58px * 1);
      text-align: center;
      width: 100%;
      height: calc(58px * 1);
      line-height: normal;
      vertical-align: center;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .icon {
      width: calc(58px * 1);
      height: calc(58px * 1);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      & img {
        width: calc(32px * 1);
        height: calc(32px * 1);
      }
    }
    &.error {
      & .icon{
        background: #F5222D66 0% 0% no-repeat padding-box;
        border: calc(1px * 1) solid #F5222D;
        border-left: 0;
      }
      border: calc(1px * 1) solid #F5222D;
    }
    &.info {
      & .icon{
        background:  #00A1AF33 0 0 no-repeat padding-box;
        border: calc(1px * 1) solid #00A1AF;
        border-left: 0;
      }
      border: calc(1px * 1) solid #00A1AF;
    }
    &.warning {
      background:transparent linear-gradient(90deg, #F77148 0%, #F7714833 100%) 0% 0% no-repeat padding-box;
    }
    &.done {
      background: transparent linear-gradient(90deg, #00CE7E 0%, #00CE7E33 100%) 0% 0% no-repeat padding-box;
    }
  }
}

@keyframes animateWidth {
  0% {
    width: calc(58px * 1);
  }
  100% {
    width: 100%
  }
}

@keyframes animateWidthOut {
  0% {
    width: 100%
  }
  100% {
    width: calc(58px * 1);
  }
}


@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}


.smallPc {
  .alertMessage {
    top: calc(116px * 1);;
    font-size: calc(16px * 1);
    min-width: calc(404px * 1);
    max-width: calc(404px * 1);

    & .msg {
      width: calc(46px * 1);

      &.out {
        width: calc(46px * 1);
      }

      &.in {
        width: 100%;
      }

      & .text {
        max-width: calc(100% - calc(46px * 1));
        height: calc(46px * 1);
      }

      & .icon {
        width: calc(46px * 1);
        height: calc(46px * 1);

        & img {
          width: calc(26px * 1);
          height: calc(26px * 1);
        }
      }
    }
  }
}

.tab {
  .alertMessage {
    top: calc(180px * 1);
    font-size: calc(12px * 1);
    width: calc(404px * 1);

    & .msg {
      width: calc(46px * 1);

      &.out {
        width: calc(46px * 1);
      }

      &.in {
        width: 100%;
      }

      & .text {
        max-width: calc(100% - 46px * 1);
        height: calc(46px * 1);
      }

      & .icon {
        width: calc(46px * 1);
        height: calc(46px * 1);

        & img {
          width: calc(26px * 1);
          height: calc(26px * 1);
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .alertMessage {
    top: 12.29vh;
    font-size: 10px;
    min-width: 87.7vw;
    max-width: 87.7vw;
    z-index: 3;
    & .msg {
      width: 36px;

      &.out {
        width: 36px;
      }

      &.in {
        width: 100%;
      }

      & .text {
        max-width: calc(100% - 36px);
        height: 36px;
      }

      & .icon {
        width: 36px;
        height: 36px;

        & img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}