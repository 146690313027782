.list {
    padding-top: 18px;
    &-item {
        list-style: none;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-left: 16px;
        height: 44px;
        margin-bottom: 7px;
        &-icon {
            margin-right: 2px;
            svg {
                width: 29px;
                margin-top: 5px;
            }
        }
        &-title {
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.48px;
            color: $text-color;
            text-shadow: 1px 1px 2px #000000;
        }
        .normal-state {
            display: inline-block;
        }
        .hover-state {
            display: none;
        }
        .pressed-state {
            display: none;
        }
        &:hover {
            .list-item-title {
                color: $text-hover-color;
                text-shadow: 1px 1px 2px $text-border-hover-color, 1px 1px 2px $text-shadow-hover-color;
            }
            .normal-state {
                display: none;
            }
            .pressed-state {
                display: none;
            }
            .hover-state {
                display: inline-block;
            }
        }
        &:active {
            .normal-state {
                display: none;
            }
            .hover-state {
                display: none;
            }
            .pressed-state {
                display: inline-block;
            }
            background: #FFD35A33 0% 0% no-repeat padding-box;
        }
    }
}