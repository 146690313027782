@import "./base/colors";
@import "./base/animation";
@import "./base/fonts";
@import "./base/reset";
@import "./components/bg";
@import "./layout/app";
@import "./layout/header";
@import "./layout/navbar";
@import "./layout/game";
@import "./components/popup";
@import "./components/playerHistory";
@import "./components/languagesPopup";
@import "./components/list";
@import "./components/menu";
@import "./components/imgLoader";
@import "./components/gameIframe";
@import "./components/imgs";
@import "./components/periodHistory";
@import "components/alertMessage";



/* width */
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
/* Track */
::-webkit-scrollbar-track { 
  background: transparent;
  width: 4px;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(135, 135, 148, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 1);
    border-radius: 4px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background:  rgba(135, 135, 148, 0.3);
}

li.show-when-mobile {
    display: none;
}

li.hide-when-mobile {
    display: flex;
}

@import "./components/responsive";